import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import Button from 'components/atoms/button';

const Wrapper = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme: { mq } }) => mq.desktop} {
    margin-right: ${({ mobile }) => (mobile === 'true' ? '0rem' : '5rem')};
  }

  ${({ theme: { mq } }) => mq.tablet} {
    opacity: 0;
    visibility: hidden;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      flex-direction: column;

      ${({ theme: { mq } }) => mq.tablet} {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.color.mainFont};
  padding: 3rem 2rem;
  margin-top: 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  position: relative;
  z-index: 5;
  transition: transform 0.25s;

  :not(:last-child) {
    margin-right: ${({ mobile }) => (mobile === 'true' ? '0rem' : '5rem')};
  }

  :nth-child(4) {
    margin-right: ${({ mobile }) => (mobile === 'true' ? '0rem' : 'auto')};
  }

  :nth-child(5) {
    ${({ theme: { mq } }) => mq.bigTablet} {
      padding-right: ${({ mobile }) => (mobile !== 'true' ? '5rem' : '0')};
    }
  }

  &:last-child::before {
    content: '';
    width: 100%;
    height: 4.5rem;
    top: 5%;
    right: 1.5rem;
    position: absolute;
    background-color: ${({ theme }) => theme.color.activebg};
    transform: scaleX(0.2);
    transform-origin: right;
    z-index: -1;
    transition: 0.25s transform;
  }

  &:hover:not(:last-child) {
    transform: translateY(-5px);
  }

  :hover:last-child::before {
    transform: scaleX(0.8);
  }

  &.activeLink {
    color: ${({ theme }) => theme.color.active};
    position: relative;
    pointer-events: none;
  }

  ${({ theme: { mq } }) => mq.tablet} {
    font-size: ${({ theme: { fontSize } }) => fontSize.m};
  }
`;

const MainNav = ({ mobile }) => {
  return (
    <Wrapper mobile={mobile}>
      <StyledLink to='/' activeClassName='activeLink' mobile={mobile}>
        Główna
      </StyledLink>
      <StyledLink to='/o-mnie' activeClassName='activeLink' mobile={mobile}>
        O mnie
      </StyledLink>
      <StyledLink to='/blog' activeClassName='activeLink' mobile={mobile}>
        Blog
      </StyledLink>
      <StyledLink to='/wazne' activeClassName='activeLink' mobile={mobile}>
        Ważne
      </StyledLink>
      <Button to='/kontakt'>kontakt</Button>
    </Wrapper>
  );
};

export default MainNav;
