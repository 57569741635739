export const breakpoints = {
  huge: 1700,
  bigDesktop: 1440,
  mediumDesktop: 1360,
  desktop: 1168,
  bigTablet: 1028,
  tablet: 767,
  bigPhone: 480,
  phone: 374,
};

export const theme = {
  color: {
    main: '#1A1F25',
    mainDark: 'rgba(20, 20, 20)',
    mainLighter: '#282f38',
    mainFont: '#fffffdee',
    darkFont: '#777888',
    light: '#fff',
    light2: '#efeff7',
    active: '#FFD70f',
    activebg: '#ffffff3d',
    devider: '#efefef0d',
    fb: '#474DC4',
  },
  fontSize: {
    xxs: '11px',
    xs: '12.5px',
    s: '17.5px',
    m: '20px',
    blogM: '18px',
    l: '25px',
    xl: '35px',
    headingBig: '60px',
  },
  fontWeight: {
    thin: 400,
    regular: 500,
    bold: 700,
  },
  shadow: {
    borderShadow: `0 0 1.3rem -0.5rem #1A1F25`,
    borderShadow2: `0 0 2rem -0.5rem #1A1F25`,
    borderShadowLight: `0 0 1rem -0.5rem #efeff7`,
  },
  radius: {
    basic: '5px',
    rounded: '10px',
  },
  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (max-width: ${breakpoints[breakpoint]}px)`;
    return acc;
  }, {}),
};
