import React, { useContext } from 'react';
import styled from 'styled-components';

import { MenuContext } from 'context/menuContext';

const Wrapper = styled.button`
  position: absolute;
  display: inline-block;
  right: 5%;
  width: 6rem;
  height: 6rem;
  background: none;
  border: none;
  outline: none;

  opacity: 0;
  visibility: hidden;
  ${({ theme: { mq } }) => mq.tablet} {
    opacity: 1;
    visibility: visible;
  }
`;

const BurgerBars = styled.span`
  margin: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 3px;
  outline: none;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: ${({ theme: { color } }) => color.light};
  transition: all 0.75s;

  :first-of-type {
    transform: ${({ isMenuOpen }) =>
      isMenuOpen
        ? 'translate(-50%, -50%) rotate(225deg)'
        : 'translate(-50%, -400%)'};
  }
  :nth-of-type(2) {
    transform: ${({ isMenuOpen }) =>
      isMenuOpen
        ? 'translate(-50%, -50%) rotate(360deg)'
        : 'translate(-50%, -50%)'};
    opacity: ${({ isMenuOpen }) => (isMenuOpen ? 0 : 1)};
  }
  :last-of-type {
    transform: ${({ isMenuOpen }) =>
      isMenuOpen
        ? 'translate(-50%, -50%) rotate(315deg)'
        : 'translate(-50%, 300%)'};
  }
`;

const Hamburger = () => {
  const { isMenuOpen, toggleMenu } = useContext(MenuContext);

  return (
    <Wrapper onClick={() => toggleMenu(!isMenuOpen)}>
      <BurgerBars isMenuOpen={isMenuOpen}></BurgerBars>
      <BurgerBars isMenuOpen={isMenuOpen}></BurgerBars>
      <BurgerBars isMenuOpen={isMenuOpen}></BurgerBars>
    </Wrapper>
  );
};

export default Hamburger;
