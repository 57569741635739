import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { MenuContext } from 'context/menuContext';
import { usePageUrl } from 'hooks/usePageUrl';

import LogoImg from 'images/svg/logo-kns.svg';
import MainNav from 'components/modules/mainNav';
import Hamburger from 'components/modules/hamburger';

const StyledHeader = styled.header`
  height: 14rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  pointer-events: none;
`;

const StyledNavBox = styled.div`
  padding: 2rem calc((100% - 128rem) / 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 1s 0.25s;
  width: 100%;
  top: 0px;
  background-color: ${({ theme }) => theme.color.main};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.devider}`};
  transform: translateY(0);
  pointer-events: auto;

  &.notVisible {
    transform: translateY(-200px);
  }

  ${({ theme: { mq } }) => mq.tablet} {
    padding: 0 calc((100% - 128rem) / 2);
  }
`;

const LogoLink = styled(Link)`
  margin: 0 10rem 0 1rem;
  pointer-events: ${({ path }) =>
    path === 'http://localhost:8000/' ? 'none' : 'auto'};
`;

const Logo = styled(LogoImg)`
  height: 10rem;
  width: 11rem;
`;

const Header = () => {
  const { isMenuOpen } = useContext(MenuContext);
  const wrapper = useRef(null);

  const url = usePageUrl();
  useEffect(() => {
    const stickyNav = wrapper.current;
    let lastScrollPosition = 0;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 150 && !stickyNav.classList.contains('notVisible')) {
        stickyNav.classList.add('notVisible');
      }
      if (scrollPosition < lastScrollPosition) {
        stickyNav.classList.remove('notVisible');
      }
      lastScrollPosition = scrollPosition;
    };

    if (isMenuOpen) {
      document.removeEventListener('scroll', handleScroll);
    } else {
      document.addEventListener('scroll', handleScroll);
    }

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <StyledHeader>
      <StyledNavBox ref={wrapper}>
        <LogoLink to='/' path={url}>
          <Logo />
        </LogoLink>
        <MainNav />
        <Hamburger />
      </StyledNavBox>
    </StyledHeader>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
