import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import gsap from 'gsap';
import Cookie from 'cookie.js';

const Wrapper = styled.div`
  position: fixed;
  padding-bottom: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: ${({ theme: { fontSize } }) => fontSize.xs};
  text-align: center;
  background-color: ${({ theme: { color } }) => color.mainDark};
  z-index: 200000;
  transform: translateY(100%);
  opacity: 0;

  div {
    margin: 0;
    padding: 1rem 0;
  }

  p {
    margin: 0;
    padding: 0rem;
  }

  ${({ theme: { mq } }) => mq.tablet} {
    font-size: ${({ theme: { fontSize } }) => fontSize.xxs};
    padding: 0 10px 10px;
  }
`;

const StyledButton = styled.button`
  margin-left: 2rem;
  padding: 0.5rem 1rem;
  color: inherit;
  border: 2px solid ${({ theme: { color } }) => color.activebg};
  border-radius: 5px;
  background: none;
  cursor: pointer;

  :hover {
    color: ${({ theme: { color } }) => color.active};
    border-color: currentColor;
  }
`;

const StyledCookieInfo = styled.p`
  font-size: ${({ theme: { fontSize } }) => fontSize.xxs};
`;

const StyledLink = styled(Link)`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
  text-decoration: underline;
`;

const Cookies = () => {
  const CookiesBox = useRef(null);
  const [isAccepted, setAccept] = useState('true');
  useEffect(() => {
    const cookieBtn = CookiesBox.current.querySelector('button');

    let currentStatus = Cookie.get('cookieStatus');
    setAccept(currentStatus);

    const handleClick = () => {
      Cookie.set('cookieStatus', 'true', {
        expires: Date.now() + 60 * 50000000,
      });

      currentStatus = Cookie.get('cookieStatus');
      setAccept(currentStatus);
    };

    cookieBtn.addEventListener('click', handleClick);

    const endPos = isAccepted === 'true' ? '100%' : '0';
    const delay = isAccepted === 'true' ? 0.2 : 3;

    gsap.to(CookiesBox.current, {
      duration: 0.5,
      delay: delay,
      y: endPos,
      opacity: 1,
    });

    return () => {
      cookieBtn.removeEventListener('click', handleClick);
    };
  }, [isAccepted, setAccept]);

  return (
    <Wrapper ref={CookiesBox}>
      <div>
        <p>
          Ta strona używa plików cookies!
          <StyledButton>Zgadzam się na ciasteczka!</StyledButton>
        </p>
      </div>
      <StyledCookieInfo>
        Zlecamy korzystać z ciasteczek, ponieważ usprawniają one pracę witryny,
        jeżeli jednach chciałbyś je wyłączyć kliknij{' '}
        <StyledLink to='/polityka-prywatnosci'>tutaj</StyledLink> aby dowiędzieć
        się jak to zrobić.
      </StyledCookieInfo>
    </Wrapper>
  );
};

export default Cookies;
