import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  background: none;
  border: none;
`;

const ButtonLink = styled(Link)`
  position: relative;
  display: inline-block;
  padding: 3rem 0;
  margin-top: 0;
  font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme, color }) =>
    color === 'dark' ? theme.color.main : theme.color.mainFont};
  text-decoration: none;
  text-transform: lowercase;
  background: none;
  border: none;
  outline: none;
  z-index: 5;
  cursor: pointer;
  transition: color 0.25s 0.05s;

  ::before {
    content: '';
    position: absolute;
    width: 110%;
    height: 45px;
    top: 15%;
    right: -5%;
    background-color: ${({ theme, color }) =>
      color === 'dark' ? theme.color.active : theme.color.activebg};
    transform: scaleX(0.2);
    transform-origin: right;
    z-index: -1;
    transition: transform 0.25s;
  }

  :hover::before {
    transform: scale(1);
    background-color: ${({ theme: { color } }) => color.active};
  }
  :hover {
    color: ${({ theme: { color } }) => color.main};
  }
`;

const ButtonLi = ({ children, to, color }) => (
  <Wrapper>
    <ButtonLink color={color} to={to}>
      {children}
    </ButtonLink>
  </Wrapper>
);

export default ButtonLi;

// const Button = styled(Link)`
//   position: relative;
//   display: inline-block;
//   padding: 3rem 2rem;
//   margin-top: 0;
//   font-weight: ${({ theme }) => theme.fontWeight.bold};
//   color: ${({ theme, color }) =>
//     color === 'dark' ? theme.color.main : theme.color.mainFont};
//   text-decoration: none;
//   text-transform: lowercase;
//   background: none;
//   border: none;
//   outline: none;
//   z-index: 5;
//   cursor: pointer;
//   transition: transform 0.25s;

//   ::before {
//     content: '';
//     position: absolute;
//     width: 100%;
//     height: 4.5rem;
//     top: 5%;
//     right: 1.5rem;
//     background-color: ${({ theme, color }) =>
//       color === 'dark' ? theme.color.active : theme.color.activebg};
//     transform: scaleX(0.2);
//     transform-origin: right;
//     z-index: -1;
//     transition: 0.25s transform;
//   }

//   :hover::before {
//     transform: ${({ wide }) => (wide ? 'scaleX(0.9)' : 'scaleX(0.8)')};
//   }
// `;
