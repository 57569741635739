import React from 'react';
import styled, { css } from 'styled-components';

import FbIcon from 'images/svg/fb-icon.svg';
import FbGroupIcon from 'images/svg/facebook-group.svg';
import InstaIcon from 'images/svg/instagram-icon.svg';
import YoutubeIcon from 'images/svg/yt.svg';

const Wrapper = styled.div`
  ${({ orientation }) =>
    orientation === 'vertical' &&
    css`
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      font-size: 16px;

      & > * {
        margin-bottom: 5rem;
        display: flex;
        align-items: center;

        span {
          margin-left: 2rem;
        }
      }
    `}

  svg {
    min-width: 3rem;
    min-height: 3rem;
    width: 3rem;
    height: 3rem;
    fill: ${({ theme }) => theme.color.darkFont};
    transition: 0.25s;
  }
  a:not(:last-child) {
    margin-right: 5rem;
  }
  a:hover svg,
  a:hover span {
    color: ${({ theme }) => theme.color.active};
    fill: currentColor;
  }
`;

const SocialMediaBox = ({ orientation }) => (
  <Wrapper orientation={orientation}>
    <a
      href='https://www.facebook.com/kamil.zielin'
      target='_blank'
      rel='noreferrer'
    >
      <FbIcon />{' '}
      {orientation === 'vertical' && <span>Kamil Zieliński</span>}
    </a>
    <a
      href='https://www.facebook.com/KoncepcjaNaSukces'
      target='_blank'
      rel='noreferrer'
    >
      <FbGroupIcon />{' '}
      {orientation === 'vertical' && <span>Fanpage</span>}
    </a>
    <a
      href='https://www.instagram.com/storyyteler/'
      target='_blank'
      rel='noreferrer'
    >
      <InstaIcon />{' '}
      {orientation === 'vertical' && <span>storyyteler</span>}
    </a>
    <a href='https://www.youtube.com/channel/UCr7TTWTdtwRDvBy1bk87Lhg?sub_confirmation=1' target='_blank' rel='noreferrer'>
      <YoutubeIcon /> {orientation === 'vertical' && <span>KNSYoutube</span>}
    </a>
  </Wrapper>
);

export default SocialMediaBox;
