import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    font-family: 'Red Hat Text', sans-serif;
    ${({ theme: { mq } }) => mq.bigDesktop} {font-size: 60%;}
    ${({ theme: { mq } }) => mq.mediumDesktop} {font-size: 57%;}
    ${({ theme: { mq } }) => mq.desktop} {font-size: 55%;}
    ${({ theme: { mq } }) => mq.bigTablet} {font-size: 50%;}
    ${({ theme: { mq } }) => mq.tablet} {font-size: 40%;}
    ${({ theme: { mq } }) => mq.phone} {font-size: 35%;}
  }

  body {
    padding-top: 14rem;
    font-family: 'Red Hat Text', sans-serif;
    font-size: 2rem;
    background-color: ${({ theme }) => theme.color.main};
    color: ${({ theme }) => theme.color.mainFont};
    overflow: hidden;

    > div {
      margin-top: 0;
      min-height: 100vh;

      > div {
        margin: 0;
        padding: 0;
        min-height: 100vh;
        display: flex;
        flex-direction: column;

      }
    }
  }

  * + * {
    margin-top: 1rem;
  }
  h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: 1;
        color: ${({ theme }) => theme.color.mainFont};
        @media only screen and (max-width: 62.5em) {
          text-align: center;
        }
        + * {
          margin-top: 0.5rem;
        }
      }
      h1 {
        font-size: 6rem;
        margin-bottom: 8rem;
      }
      h2 {
        font-size: 5rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
      }
      h3 {
        font-size: 4rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
      }
      h4 {
        font-size: 3rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
      h5 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
      h6 {
        font-size: 2rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }

      p {
        line-height: 1.5;
        font-size: 16px;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

`;

export default GlobalStyle;
