import React, { useState } from 'react';

export const MenuContext = React.createContext({});

export const MenuProvider = ({ children }) => {
  const [isMenuOpen, toggleMenu] = useState(false);
  return (
    <MenuContext.Provider value={{ isMenuOpen, toggleMenu }}>
      {children}
    </MenuContext.Provider>
  );
};
