import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/GlobalStyles';

import { MenuProvider } from 'context/menuContext';
import { theme } from 'theme/mainTheme';

import Header from '../modules/header';
import Footer from 'components/modules/footer';
import './layout.css';
import MobileNav from 'components/modules/mobileNav';
import Cookies from 'components/modules/cookies';

const StyledWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 0 1.45rem;
  background-color: ${({ theme }) => theme.color};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  main {
    width: 100%;
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <MenuProvider>
          <Header siteTitle='KNS' />
          <MobileNav />
        </MenuProvider>
        <StyledWrapper>
          <main>{children}</main>
        </StyledWrapper>
        <Footer />
        <Cookies></Cookies>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
