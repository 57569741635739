import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import SocialMediaBox from 'components/modules/socialMediaBox';

const StyledFooter = styled.footer`
  margin-top: auto;
  position: relative;
  min-height: 15rem;
  padding: 5rem calc((100% - 128rem) / 2);
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.color.devider};
  align-items: center;
  justify-items: center;
  text-align: center;
  background-color: rgba(20, 20, 20);

  color: ${({ theme }) => theme.color.darkFont};
`;

const StyledAuthorInfo = styled.p`
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
  font-size: ${({ theme: { fontSize } }) => fontSize.s};

  ${({ theme: { mq } }) => mq.tablet} {
    font-size: ${({ theme: { fontSize } }) => fontSize.xs};
  }
  a {
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
    &:hover {
      color: ${({ theme: { color } }) => color.active};
    }
  }
`;

const StyledNav = styled.nav`
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid gray;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  & > * {
    margin-top: 0;

    :hover {
      color: ${({ theme }) => theme.color.active};
    }
  }

  a.activeLink {
    color: ${({ theme }) => theme.color.active};
    position: relative;
    pointer-events: none;
  }
`;

const StyledLink = styled(Link)`
  font-size: ${({ theme: { fontSize } }) => fontSize.s};
  transition: color 0.25s;
  :hover {
    color: ${({ theme: { color } }) => color.active};
  }

  ${({ theme: { mq } }) => mq.tablet} {
    font-size: ${({ theme: { fontSize } }) => fontSize.xs};
  }
`;

const Footer = () => (
  <StyledFooter>
    <StyledNav>
      <Link to='/' activeClassName='activeLink'>
        Główna
      </Link>
      <Link to='/o-mnie' activeClassName='activeLink'>
        O mnie
      </Link>
      <Link to='/blog' activeClassName='activeLink'>
        Blog
      </Link>
      <Link to='/wazne' activeClassName='activeLink'>
        Ważne
      </Link>
    </StyledNav>
    <SocialMediaBox />
    <StyledAuthorInfo>
      <span>
        All rights reserved to Koncepcja na Sukces © 2020 -{' '}
        {new Date().getFullYear()}
      </span>
      <span>
        Developed with brother love by{' '}
        <a href='https://mateuszgruzla.pl/' target='_blank' rel='noreferrer'>
          Mateusz Gruźla
        </a>
      </span>
    </StyledAuthorInfo>
    <StyledLink to='/polityka-prywatnosci'>Polityka prywatności</StyledLink>
  </StyledFooter>
);

export default Footer;
