import React, { useContext } from 'react';
import styled from 'styled-components';

import { MenuContext } from 'context/menuContext';

import MainNav from 'components/modules/mainNav';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh + 15rem);
  background-color: ${({ theme: { color } }) => color.mainDark};
  z-index: 500;
  margin: -15rem 0 0 0;
  transform: ${({ isMenuOpen }) =>
    isMenuOpen ? 'translateY(0)' : 'translateY(-100%)'};
  transition: transform 0.75s;

  opacity: 0;
  visibility: hidden;
  ${({ theme: { mq } }) => mq.tablet} {
    opacity: 1;
    visibility: visible;
  }
`;

const MobileNav = () => {
  const { isMenuOpen } = useContext(MenuContext);
  return (
    <Wrapper isMenuOpen={isMenuOpen}>
      <MainNav mobile='true' />
    </Wrapper>
  );
};

export default MobileNav;
